<!--前室, 排烟风机房-->
<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
<!--    前室-送风口-->
    <div class="wind" v-if="parameter.qianshi">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <div class="panl">
        <div class="up open">
          <div  class="round"></div>
        </div>
        <div class="inside">
          <div class="start-stop">
            <div class="white" style="background-color: #fff; ">
              <div class="inner">
              </div>
            </div>
          </div>
<!--          pointer关  pointer trun 开-->
          <div class="pointer" :class="parameter.class&&parameter.class.pointer" @click="buttonSelect('close')"></div>
          <div class="start-stop-font">
            <span  style="color: white;display: inline-block;float: left">关</span>
            <span style="color: white;display: inline-block;float: right">开</span>
          </div>

          <div class="right" >
            <div class="right-line">
            </div>
            <div class="right-round" @click="buttonSelect(parameter.class.pointer)">
            </div>
          </div>

        </div>
        <div class="down">
          <div class="list">
            <div v-for="index in 14" class="item" ></div>
          </div>
        </div>
      </div>

    </div>
<!--    风机房控制柜-->
    <div class="kongzhigui" v-if="parameter.kongzhigui">
    <img  :src="zoomOutImg" class="zoomOut" @click="onClose" />
<!--    <div class="erow">-->
<!--      <div  class="power">-->
<!--        <span >电源指示灯</span>-->
<!--        <span class="light" :class="parameter.class&&parameter.class.dian"></span>-->
<!--      </div>-->
<!--      <div  class="power" style="background-color:transparent;border: 0">-->

<!--      </div>-->
<!--      <div class="run power">-->
<!--        <span>运行指示灯</span>-->
<!--        <span  class="light " :class="parameter.class&&parameter.class.yunxing"></span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="erow" style="margin-top: 5.5rem">-->

<!--      <div  class="power">-->
<!--        <span>&#8194;启动按钮&#8194;</span>-->
<!--        <span class=" start-btn light"  style="border:0.15rem solid #777;"></span>-->
<!--      </div>-->
<!--      <div  class="power" style="background-color:transparent;border: 0"></div>-->

<!--      <div   class="run power">-->
<!--        <span  >&#8194;停止按钮&#8194;</span >-->
<!--        <span @click="buttonSelect('stop')"  class="stop-btn light" style="border:0.15rem solid #777;" ></span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <img src="https://bankaoedu.com/files/xc/12-1-2-e-switch.png"   style="width: 2.25rem;position: relative;left:13rem;bottom: -3rem"/>-->
<!--    <div class="erow">-->
<!--      <div class="power" style="background-color:transparent;border: 0"></div>-->
<!--      <div class="power">-->
<!--        <div >手动 / 自动</div>-->
<!--        <div class=" start-btn light" style="width: 4rem;height: 4rem;border-radius: 50%;background-color: #555;box-shadow:0.15rem 0.15rem 1rem 0.2rem  #777;">-->
<!--&lt;!&ndash;       switch手动      switch trans 自动&ndash;&gt;-->
<!--          <div class="switch" :class="parameter.class&&parameter.class.switch" @click="buttonSelect(parameter.class.switch)"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="power" style="background-color:transparent;border: 0"></div>-->
<!--    </div>-->
      <img :src="require('@/assets/img/5/5-3zidong.png')" style="width: 33.25rem;"/>
<!--      电源指示灯-->
      <div class="dian"><span class="light" :class="parameter.class&&parameter.class.dian"></span></div>
<!--运行指示灯-->
      <div class="yunxing"><span class="light " :class="parameter.class&&parameter.class.yunxing"></span></div>
<!--      停止按钮-->
      <div class="stop"><span @click="buttonSelect('stop')"  class="stop-btn light" style="border:0.15rem solid #777;" ></span></div>
<!--      手自开关  switch手动      switch trans 自动-->
      <div class="switch-box">
        <div class="switch" :class="parameter.class&&parameter.class.switch" @click="buttonSelect(parameter.class.switch)"></div>
      </div>
  </div>
<!--    风机房-防火阀-->
    <div class="fireDamper" v-if="parameter.fireDamper">
      <img  :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <img  :src="parameter.imgOpen" v-if="parameter.switch==='open'" style="width: 26rem"/>
      <img  :src="parameter.imgClose" v-if="parameter.switch==='close'" style="width: 26rem"/>
      <div class="gan" v-if="parameter.switch==='open'" @click="buttonSelect(parameter.switch)"/>
<!--      // v-if="parameter.switch==='close'"-->
      <div class="quan" v-if="parameter.switch==='open'" @click="buttonSelect(parameter.switch)"></div>
    </div>
<!--    房间-排烟控制板 Smoke exhaust control panel-->
    <div class="smokeExhaust"  v-if="parameter.smokeExhaust">
      <img  :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <img  :src="require('@/assets/img/5/5-5-2.png')" class="zoomImg" />
      <img :src="parameter.huojingyuan.openImg" v-if="parameter.openImg" ref = 'openImg' class="openImg" style="position: absolute;height: 35.65rem;top: 26.8%;left: 61.827048%;"/>
      <div  class="huojingyuan" :class="parameter.huojingyuan&&parameter.huojingyuan.class_" @click="buttonSelect" ></div>
      <div class="reset-rotation">
        <!--        复位1-->
        <div class="resetquan1" :class="parameter.class&&parameter.class.resetquan1" @click="buttonSelect(1)"></div>
        <div class="resetgun1 rotation1" style="display: none"><img :src="require('@/assets/img/5/gun.png')"></div>
        <div class="resetfangxiang1 rotation1"  style="display: none" @click="buttonSelect(1,$event)"><img :src="require('@/assets/img/5/direction.png')"></div>

        <!--        复位2-->
        <div class="resetquan2" :class="parameter.class&&parameter.class.resetquan2" @click="buttonSelect(2)"></div>
        <div class="resetgun2 rotation2" style="display: none"><img :src="require('@/assets/img/5/gun.png')"></div>
        <div class="resetfangxiang2 rotation2" style="display: none" @click="buttonSelect(2,$event)"><img :src="require('@/assets/img/5/direction.png')"></div>
      </div>
      <div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parameter: {},
    close: {
      type: Function,
    },
  },
  data() {
    return {
      zoomOutImg:require('@/assets/img/zoomOut.png'),
      isShow: false,
      closeAudio:'magnifying-xiao.mp3',
      keyboardAudio:'keyboard.mp3',
    };
  },
  mounted() {
  },
  created() {
    console.log(this.parameter)
  },

  methods: {
    show() {
      this.isShow = true;
    },
    onClose() {
      this.close && this.close();
      this.$emit('customPlay2', this.closeAudio);
    },

    buttonSelect(value,e) {
      console.log('antechamber-buttonSelect',value)
      if(this.parameter[value]){
        if(this.parameter[value]['up']&&(!this.parameter[value]['submit']||(this.parameter[value]['submit']&&(this.parameter[value]['submit']['equal']===1&&this.parameter.branchKey===this.parameter[value]['submit']['branch']||this.parameter[value]['submit']['equal']===3)))){
          let arr=this.parameter[value]['up'];
          let new_=this.arrcover(arr,this.parameter)
          this.$set(this.parameter,new_)
          console.log('buttonSelect-up',value)
        }
      }
      let submit=[];let update_=[];
      if(this.parameter[value]&&this.parameter[value]['submit']){
        submit=this.parameter[value]['submit'];
      }
      if(this.parameter[value]&&this.parameter[value]['update']){
        console.log('subUpdate1',this.parameter[value]['update'])
        update_= this.parameter[value]['update'];
      }

      this.$emit('customOther', value,submit,update_);
      this.$emit('customPlay2', this.keyboardAudio);
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>

<style lang="less" scoped>
.wind{
  border: .5rem solid #fff;
  border-radius: 1.25rem;
  background: #BABFC2;
  background-size: 15rem 15rem;
  position: relative;
  width: 33.25rem;
  height: 47.0625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.875rem 1.875rem;
  color: #000;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .right-line{
    width: 1.1rem;
    height: .35rem;
    background-color:#131312;
    position: relative;
    top:6.75rem;
    left:0rem
  }
  .right-round{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: .2rem solid #131312;
    position: relative;
    top:5.75rem;
    left:1rem
  }
  .right{

    width: 3rem;
    height: 8rem;
    position: relative;
    top:-7.5rem;
    left:6rem;
    //background-color: #9c000e;
  }
  .start-stop-font{
    height: 1rem;
    width: 5rem;

    position: absolute;
    left: 3rem;
    top: 7rem;
    z-index: 99;
  }
  .panl {
    border-radius: 0.25rem;
    background-color: #C4C4C6;
    height:30rem;
    width: 17rem;
    position: relative;
    top: 9.5rem;
    border-left: 0.25rem solid #CFD2D5;
    border-top: 0.25rem solid #CFD2D5;
    box-shadow: 0.15rem 0.15rem 0.15rem 0.175rem #979DA7;

  }
  .up{
    border-left:0.2rem solid #B0B0B1;
    border-bottom:0.2rem solid #B0B0B1;
    border-right:0.15rem solid #BDBDBE;
    height: 10rem;
    width: 13rem;
    margin-top: 2rem ;
    margin-left: 2rem ;
    transform-origin: top center;
    transition: 1s;
    float: left;
    background-color:  #C4C4C6;
  }
  .inside{
    border-left:0.2rem solid #B0B0B1;
    border-bottom:0.2rem solid #B0B0B1;
    border-right:0.15rem solid #BDBDBE;
    height: 10rem;
    width: 13rem;
    margin-top: 2rem ;
    margin-left: 2rem ;
    background: linear-gradient(-180deg, #787879, #C5C5C7);

    //background-color: #787879;
  }
  .pointer{
    height: 3.5rem;
    width: 0.5rem;
    background-color: #413940;
    position: absolute;
    left: 5.45rem;
    top: 5rem;
    z-index: 9999;
    border-radius: .75rem ;
    transform: rotate(-45deg);
    border-bottom: 5px solid red;
    transition: all 1.5s;
    transform-origin: bottom center;
  }
  .trun{
    transform: rotate(45deg);
  }
  .start-stop{
    width: 5.25rem;
    height: 8rem;
    position: relative;
    top: 1.25rem;
    left: 0.75rem;
    border: .25rem solid #fff;
    border-radius: .5rem;
    background-color: #FF0000;

  }
  .inner{
    width: 4.5rem;
    height: 4.5rem;
    position: relative;
    left:0.75rem;
    top:0.75rem;
    border: 1px solid red;
    border-radius: 50%;
    background-color: #FF0000;
  }
  .white{
    width: 6rem;
    height: 6rem;
    position: relative;
    left:-0.5rem;
    top:2rem;
    border-radius: 50%;
    clip-path: polygon(50% 50%, 100% 5%, 100% 50%, 100% 0, 7% 0, 5% 0, 5% 0)
  }
  .open{
    transform:rotateX(150deg);
    background-color: #999999;
    border: .25rem  solid #C6C6C8;
    border-bottom: 0.25rem solid #666666;
    z-index:10;

  }
  .round{
    background-color: #999999;
    box-shadow:0.15rem 0.15rem 0.15rem 0.15rem #565B62;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    position: relative;
    top: 6.75rem;
    left:5.5rem;
    z-index:10;
  }
  .down{
    margin-top: 0.2rem;
    height: 16rem;
    width: 13rem;
    display:flex;
    flex-direction:row;
    margin-left: 2rem;
    flex-wrap:nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    background-color: #5d5e5b;
    z-index:10;
  }
  .list{
    display: flex;
    flex-direction:column;
    background-color:#C4C4C6 ;
    border:0.1rem solid #70716C;
  }
  .item{
    background-color: #70716C;
    padding-top: 0.2rem;
    border-top: 2px solid #A2A2A0;
    height: 1.5rem;
    width: 13rem;
    transform: rotateX(0deg);
    transition: all 1.5s;
    transform-origin: top center;
  }
  .itemOopen{
    transform: rotateX(-45deg);
    transition: all 1.5s;
    transform-origin: top center;
    background-color: #70716C;
  }
  .green{
    background-color: #a2ffa5;

  }
  .stop-btn{
    background-color: #700;

  }
  .start-btn{
    background-color: #007719;


  }
  .start{


  }
  .switch{
    width: 0.6rem;height: 4rem;margin: 0 auto;background-color: #fff;border-radius: 0.5rem;transform: rotate(-25deg);box-shadow:0.075rem 0.075rem 0.075rem 0.075rem  #bebebe;
    transition: all 1s;
  }
  .trans{
    transform: rotate(25deg);
    transition: all 1s;
  }

  .light {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border-radius: 2rem;
    margin-top: 1.25rem;
    position: relative;
  }
  .erow{
    display: flex;
    align-items: center;
    width: 20.625rem;
    margin-top: 3.75rem;
    justify-content: space-between;

  }


  .power{
    width: 7.5rem;
    height: 2.875rem;
    background-color: #9c000e;
    color: #fff;
    text-align: center;
    line-height: 2.875rem;
    font-weight: 800;
    border-bottom: .5rem solid #1b4084;


  }
  .zoomOut {
    width: 2.8125rem;
    height: 2.8125rem;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;

  }
}

.kongzhigui1{
  border: .5rem solid #fff;
  border-radius: 1.25rem;
  background: url('https://bankaoedu.com/files/xc/12-1-2-background.png') repeat;
  background-size: 15rem 15rem;
  position: relative;
  width: 33.25rem;
  height: 47.0625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.875rem 1.875rem;
  color: #000;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .grey {
    background-color: #fff;
  }
  .red {
    background-color: #ffa39d;
  }
  .green{
    background-color: #a2ffa5;
  }
  .stop-btn{
    background-color: #700;
  }
  .start-btn{
    background-color: #007719;
  }
  .start{
  }
  .switch{
    width: 0.6rem;height: 4rem;margin: 0 auto;background-color: #fff;border-radius: 0.5rem;transform: rotate(-25deg);box-shadow:0.075rem 0.075rem 0.075rem 0.075rem  #bebebe;
    transition: all 1s;
  }
  .trans{
    transform: rotate(25deg);
    transition: all 1s;
  }

  .light {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border-radius: 2rem;
    margin-top: 1.25rem;
    position: relative;
  }
  .erow{
    display: flex;
    align-items: center;
    width: 20.625rem;
    margin-top: 3.75rem;
    justify-content: space-between;

  }

  .power{
    width: 7.5rem;
    height: 2.875rem;
    background-color: #9c000e;
    color: #fff;
    text-align: center;
    line-height: 2.875rem;
    font-weight: 800;
    border-bottom: .5rem solid #1b4084;


  }
  .zoomOut {
    width: 2.8125rem;
    height: 2.8125rem;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;

  }
}
.kongzhigui{
  scale: 0.85;
  border: 0.5rem solid #fff;
  border-radius: 1.25rem;
  background: url('https://bankaoedu.com/files/xc/12-1-2-background.png') repeat;
  background-size: 15rem 15rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.875rem 1.875rem;
  color: #000;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .grey {
    background-color: #fff;
  }
  .red {
    background-color: #ffa39d;
  }
  .green{
    background-color: #a2ffa5;
  }
  .stop-btn{
    background-color: #700;
  }
  .start-btn{
    background-color: #007719;
  }
  .dian{
    position: absolute;
    //top:  20.6%;
    //left:  28.9%;
    top: 11.8rem;
    left: 10.7rem;
  }
  .yunxing{
    position: absolute;
    //top:  20.6%;
    //left: 65.5%;
    top: 11.8rem;
    left: 24.18rem;
  }
  .stop{
    position: absolute;
    //top: 39.9%;
    //left: 65.5%;
    top: 21.98rem;
    left: 24.18rem;
    cursor: pointer;
  }
  .light {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border-radius: 2rem;
    box-shadow: 0.15rem 0.15rem 1rem 0.2rem #777;
  }
  .switch-box{
    position: absolute;
    //top: 65.4%;
    //left: 44.5%;
    top: 33.3rem;
    left: 16.4rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    background-color: #555;
    box-shadow:0.15rem 0.15rem 1rem 0.2rem  #777;
    cursor: pointer;
  }

  .switch{
    width: 0.6rem;height: 4rem;margin: 0 auto;background-color: #fff;border-radius: 0.5rem;transform: rotate(-25deg);box-shadow:0.075rem 0.075rem 0.075rem 0.075rem  #bebebe;
    transition: all 1s;
  }

  .trans{
    transform: rotate(25deg);
    transition: all 1s;
  }

  .zoomOut {
    width: 2.8125rem;
    height: 2.8125rem;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }
}
///deep/.el-dialog {
//  display: flex;
//  flex-direction: column;
//  margin: 0 !important;
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
//  max-height: calc(100% - 1.875rem);
//  max-width: calc(100% - 1.875rem);
//  background: transparent;
//  box-shadow: none;
//  width: auto;
//}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}
/deep/ .el-dialog__body{
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fireDamper{
  position: relative;
  border: 0.5rem solid #fff;
  border-radius: 1.25rem;
  .zoomOut{
    right: 1rem;
    top: 1rem;
  }
  .gan{
    position: absolute;
    top: 9.8rem;
    left: 14rem;
    width: 4rem;
    height: 12.7rem;
    transform: rotate(30deg);
  }
  .quan{
    border: 1.7rem solid #00000000;
    border-radius: 2rem;
    position: absolute;
    top: 18.6rem;
    right: 0.26rem;
    cursor: pointer;
  }
}
.smokeExhaust{
  position: relative;
  scale: 0.6;
  .zoomOut{
    width: 3rem;
    height: 3rem;
    right: 1.5rem;
    top: 1.5rem;
  }
  .huojingyuan {
    height: 2.7rem;
    width: 2.7rem;
    border-radius: 2.7rem;
    background: orange;
    position: absolute;
    top: 69.4%;
    left: 21%;
  }
  .huojingyuan:hover {
    cursor: pointer;
  }
  .huojingyuanhua1 {
    animation: moveRightLeft 1.5s infinite alternate;
    animation-iteration-count: 1;
  }
  .huojingyuanhua {
    transform: translateX(5.7rem);
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(0);
    } /* 起始位置 */
    50% {
      transform: translateX(5.7rem);
    } /* 向左移动 */
    100% {
      transform: translateX(0rem);
    } /* 返回原位置 */
  }
  .reset-rotation {
    color: #000;
    .resetquan1 {
      position: absolute;
      top: 53%;
      left: 22.9%;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 4.5rem;
    }
    .resetgun1 {
      position: absolute;
      top: 53.996283%;
      left: 8.842653%;
      height: 3.531599%;
      width: 17.425228%;
      img{
        width: 100%;
      }
    }
    .resetgun1zhuan {
      animation: resetgun1zhuan 1.3s linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
    .resetfangxiang1 {
      position: absolute;
      left: 10%;
      top: 45.5%;
      width: 2.25rem;
      height: 4.875rem;
      img{
        width: 100%;
      }
    }

    .resetquan2 {
      position: absolute;
      top: 21.6%;
      left: 22.9%;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 4.5rem;
    }
    .resetgun2 {
      position: absolute;
      top: 22.59%;
      left: 8.842653%;
      height: 3.531599%;
      width: 17.425228%;
      img{
        width: 100%;
      }
    }
    .resetgun2zhuan {
      animation: resetgun2zhuan 1s linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
    .resetfangxiang2 {
      position: absolute;
      left: 10%;
      top: 14%;
      width: 2.25rem;
      height: 4.875rem;
      img{
        width: 100%;
      }
    }
  }

  @keyframes resetgun1zhuan {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: 92% 61%;
      transform-origin: 92% 61%;
    }

    100% {
      -webkit-transform: rotate(0.5turn);
      transform: rotate(0.5turn);
      -webkit-transform-origin: 92% 61%;
      transform-origin: 92% 61%;
    }
  }
  @keyframes resetgun2zhuan {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: 92% 59%;
      transform-origin: 92% 59%;
    }

    100% {
      -webkit-transform: rotate(0.7turn);
      transform: rotate(0.7turn);
      -webkit-transform-origin: 92% 59%;
      transform-origin: 92% 59%;
    }
  }
}
</style>